<template>
  <div class="container">
    <div v-if="!loading && link.length > 0" class="pb-2">
      <div class="row">
        <div class="col-9 texto-superior">
          Este é o seu painel de indicação, faça bom proveito dele indicando o
          seguro Bike Registrada para toda sua galera do pedal!
        </div>
        <div class="col-3 text-left img-alinhamento">
          <img src="/img/money.svg" style="width: 100%; max-width: 100px" />
        </div>
      </div>

      <div class="mt-2 mb-5 texto-sub-superior">
        Você nosso parceiro também sai ganhando e a primeira parcela do seguro é
        totalmente sua (descontamos apenas a taxa do cartão e os impostos da
        parcela)
      </div>

      <div class="mt-3 ">
        <div class="texto-sub-superior">
          Ganhar dinheiro indicando seus amigos nunca foi tão fácil!
        </div>
        <div class="row mb-4">
          <div class="col-sm-12 col-md-8">
            <div class="input-group mb-3">
              <b-form-input
                :value="link"
                id="myInput"
                type="text"
                readonly
              ></b-form-input>
              <div class="input-group-append">
                <b-button
                  class="btn-success"
                  @click="copiarLink()"
                  variant="primary"
                  >Copiar link</b-button
                >
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <span
              class="fb-share-button mr-4"
              :data-href="link"
              data-layout="button"
              data-size="small"
            >
              <a
                target="_blank"
                :href="
                  `https://www.facebook.com/sharer/sharer.php?u=${link}&amp;src=sdkpreparse`
                "
                class="fb-xfbml-parse-ignore"
                ><b-img src="/img/facebook-logo-2019.svg" height="37px"></b-img
              ></a>
            </span>
            <span>
              <a
                class="mb-3"
                :href="
                  `https://api.whatsapp.com/send?text=Quem avisa amigo é. Não pedale sem o seguro Bike Registrada, eu uso e recomendo. Acesse ${link} 
           `
                "
                target="_blank"
                variant="success"
                ><b-img src="/img/whatsapp.svg" height="42px"></b-img>
              </a>
            </span>
          </div>
        </div>
        <div class="text-font">
          Indique seu link para maior número de pessoas que puder, assim você
          acumula mais dinheiro! <br />
          Utilize seu link em todas as indicações. Ele é a sua garantia!
          <div class="mt-3">
            <p>
              <b> Regras para indicação:</b>
              <br />
              Você só ganha em caso de indicação convertida em seguro pago e
              ativado. <br />
              Cada indicação só contabilizará uma única vez, no momento da
              compra e ativação do seguro do indicado, não contabilizando nas
              renovações.
              <br />
              Sua conta bancária deve possuir uma chave PIX e ser cadastrada
              aqui na sua área de parceiro no menu lateral. <br />
              Só serão contabilizadas a indicações que forem acessadas pelo seu
              link de indicação.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="text-center">
      <lottie-player
        src="/anim/mountain_bike.json"
        background="transparent"
        speed="1.7"
        style="width: 72px; height: 72px; margin:0 auto;"
        autoplay
        loop
        class="mt-3"
      ></lottie-player>
      <div>Carregando, por favor aguarde...</div>
    </div>
  </div>
</template>

<script>
import auth from "../../auth";
import Swal from "sweetalert2";
import axios from "axios";
import captureError from "../../helpers/captureError";
import config from "../../config";
export default {
  data() {
    return { config, auth, link: "", loading: false };
  },
  created() {
    this.buscarLink();
  },
  methods: {
    async buscarLink() {
      try {
        this.loading = true;
        var response = await axios.get(
          `${config.API_URLV2}/lojista/BuscarLinkParceiro?id=${
            auth.getUserInfo().id
          }`,
          {}
        );
        this.link = response.data.link;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível buscar seu link. Tente novamente mais tarde ou contate o suporte.",
        });
      }
    },
    copiarLink() {
      var copyText = document.getElementById("myInput");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "O link foi copiado!",
        text: `${copyText.value}`,
        showConfirmButton: true,
        timer: 20000,
      });
    },
  },
};
</script>

<style scoped>
.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.texto-superior {
  color: #23ad21;
  font-weight: 700;
  font-size: 25px;
}
.texto-sub-superior {
  font-weight: 500;
  font-size: 17px;
}
.img-alinhamento {
  text-align: left;
}
.text-font {
  font-size: 14px;
}
@media (max-width: 768px) {
  .texto-superior {
    color: #23ad21;
    font-weight: 600;
    font-size: 19px;
    margin-top: 20px;
  }
  .img-alinhamento {
    align-self: center;
  }
  .texto-sub-superior {
    font-weight: 500;
    font-size: 15px;
  }
  .text-font {
    font-size: 13px;
  }
}
</style>
